//filterParams.js
//Path:/Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/constants/filterParams.js

export const FILTER_TYPES = {
  LOCATION: 'location',
  PAGE: 'page',
  MIN_PRICE: 'minPrice',
  MAX_PRICE: 'maxPrice',
  RENT_MIN_PRICE: 'rentMinPrice',
  RENT_MAX_PRICE: 'rentMaxPrice',
  BEDS_MIN: 'bedsMin',
  BEDS_MAX: 'bedsMax',
  BATHS_MIN: 'bathsMin',
  BATHS_MAX: 'bathsMax',
  SQFT_MIN: 'sqftMin',
  SQFT_MAX: 'sqftMax',
  BUILD_YEAR_MIN: 'buildYearMin',
  BUILD_YEAR_MAX: 'buildYearMax',
  LOT_SIZE_MIN: 'lotSizeMin',
  LOT_SIZE_MAX: 'lotSizeMax',
  DAYS_ON: 'daysOn',
  SOLD_IN_LAST: 'soldInLast',
  STATUS_TYPE: 'status_type',
  HOME_TYPE: 'home_type',
  SORT: 'sort',
  IS_OPEN_HOUSES_ONLY: 'isOpenHousesOnly',
  IS_COMING_SOON: 'isComingSoon',
  IS_PENDING_UNDER_CONTRACT: 'isPendingUnderContract',
  IS_FOR_SALE_FORECLOSURE: 'isForSaleForeclosure',
  IS_PRE_FORECLOSURE: 'isPreForeclosure',
  SALE_BY_OWNER: 'saleByOwner',
  SALE_BY_AGENT: 'saleByAgent',
  IS_PARK_VIEW: 'isParkView',
  IS_WATER_VIEW: 'isWaterView',
  IS_WATERFRONT: 'isWaterfront',
  IS_CITY_VIEW: 'isCityView',
  IS_MOUNTAIN_VIEW: 'isMountainView',
  IS_NEW_CONSTRUCTION: 'isNewConstruction',
  OTHER_LISTINGS: 'otherListings',
  IS_ACCEPTING_BACKUP_OFFERS: 'isAcceptingBackupOffers',
  KEYWORDS: 'keywords',
  IS_BASEMENT_FINISHED: 'isBasementFinished',
  IS_BASEMENT_UNFINISHED: 'isBasementUnfinished',
  HAS_POOL: 'hasPool',
  HAS_AIR_CONDITIONING: 'hasAirConditioning',
  IS_3D_HOME: 'is3dHome',
  COORDINATES: 'coordinates',
  POLYGON: 'polygon', // **Added POLYGON**
  ADDRESS: 'address',   // **Added ADDRESS**
  HOA: 'hoa',
  INCLUDE_HOMES_WITH_NO_HOA_DATA: 'includeHomesWithNoHoaData',
  IS_AUCTION: 'isAuction',
  SCHOOLS: 'schools',
  SCHOOLS_RATING: 'schoolsRating',
  INCLUDE_UNRATED_SCHOOLS: 'includeUnratedSchools',
  HAS_GARAGE: 'hasGarage',
  PARKING_SPOTS: 'parkingSpots',
  IS_FORECLOSED: 'isForeclosed',
  IS_ENTIRE_PLACE: 'isEntirePlace',
  IS_ROOM: 'isRoom',
  // Remove 'ZPID' as it's not a filter
};

export const ALL_FILTER_PARAMS = [
  'location',
  'page',
  'minPrice',
  'maxPrice',
  'rentMinPrice',
  'rentMaxPrice',
  'bedsMin',
  'bedsMax',
  'bathsMin',
  'bathsMax',
  'sqftMin',
  'sqftMax',
  'buildYearMin',
  'buildYearMax',
  'lotSizeMin',
  'lotSizeMax',
  'daysOn',
  'soldInLast',
  'status_type',
  'home_type',
  'sort',
  'isOpenHousesOnly',
  'isComingSoon',
  'isPendingUnderContract',
  'isForSaleForeclosure',
  'isPreForeclosure',
  'saleByOwner',
  'saleByAgent',
  'isParkView',
  'isWaterView',
  'isWaterfront',
  'isCityView',
  'isMountainView',
  'isNewConstruction',
  'otherListings',
  'isAcceptingBackupOffers',
  'keywords',
  'isBasementFinished',
  'isBasementUnfinished',
  'hasPool',
  'hasAirConditioning',
  'is3dHome',
  'coordinates',
  'polygon', // **Added polygon**
  'address',  // **Added address**
  'hoa',
  'includeHomesWithNoHoaData',
  'isAuction',
  'schools',
  'schoolsRating',
  'includeUnratedSchools',
  'hasGarage',
  'parkingSpots',
  'isForeclosed',
  'isEntirePlace',
  'isRoom',
];
