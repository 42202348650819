//urlUtils.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/urlUtils.js
import { FILTER_TYPES } from "../constants/filterParams";

/**
 * Updates the URL with the given search parameters and filters.
 * Constructs the URL based solely on the provided filters.
 *
 * @param {Object} updatedFilters - The filters to update or add.
 * @param {string} currentSearchQuery - The current search query (location or address).
 * @param {string} currentSearchType - The type of search ('location', 'polygon', 'address').
 * @param {Array} [polygonCoords=null] - The polygon coordinates, if any.
 * @param {string|null} [zpid=null] - The zpid parameter, if any.
 * @param {Object} [existingFilters={}] - The current filters from state.
 * @returns {string} The updated URL.
 */
export const updateUrlWithSearchParams = (
  updatedFilters,
  currentSearchQuery,
  currentSearchType,
  polygonCoords = null,
  zpid = null,
  existingFilters = {}
) => {
  // Merge existing filters with updatedFilters
  const finalFilters = { ...updatedFilters };

  // Handle search type specific parameters
  if (currentSearchType === 'polygon') {
    if (Array.isArray(polygonCoords) && polygonCoords.length > 0) {
      const formattedPolygonCoords = polygonCoords.map(([lng, lat]) => `${lng} ${lat}`).join(",");
      finalFilters[FILTER_TYPES.POLYGON] = formattedPolygonCoords; // Use FILTER_TYPES.POLYGON
      // Remove mutually exclusive search parameters
      delete finalFilters[FILTER_TYPES.LOCATION];
      delete finalFilters[FILTER_TYPES.ADDRESS];
    } else {
      console.warn('polygonCoords is not an array or is empty:', polygonCoords);
      // Optionally, remove the polygon filter if invalid
      delete finalFilters[FILTER_TYPES.POLYGON];
      delete finalFilters[FILTER_TYPES.LOCATION];
      delete finalFilters[FILTER_TYPES.ADDRESS];
    }
  } else if (currentSearchType === 'location') {
    finalFilters[FILTER_TYPES.LOCATION] = currentSearchQuery;
    // Remove mutually exclusive search parameters
    delete finalFilters[FILTER_TYPES.POLYGON];
    delete finalFilters[FILTER_TYPES.ADDRESS];
  } else if (currentSearchType === 'address') {
    finalFilters[FILTER_TYPES.ADDRESS] = currentSearchQuery;
    // Remove mutually exclusive search parameters
    delete finalFilters[FILTER_TYPES.LOCATION];
    delete finalFilters[FILTER_TYPES.POLYGON];
  }

  // Remove irrelevant parameters that shouldn't persist in the URL
  delete finalFilters[FILTER_TYPES.ZPID];

  // Generate new search parameters based on finalFilters
  const searchParams = generateFilterParams(finalFilters);

  // Handle 'zpid' separately: include it only if it's valid
  if (zpid && typeof zpid === 'string' && zpid.trim() !== '') {
    searchParams.set(FILTER_TYPES.ZPID, zpid);
  }

  // Construct the new URL
  const newUrl = `/listings?${searchParams.toString()}`;

  return newUrl;
};



/**
 * Generates query parameters for filters, optionally including certain filters even if false.
 * @param {Object} filters - The filters object containing various filter parameters.
 * @param {Array} [alwaysInclude=[]] - List of filter keys to always include, even if false.
 * @returns {URLSearchParams} - The URLSearchParams object with filtered filter parameters.
 */
export const generateFilterParams = (filters, alwaysInclude = []) => {
  const searchParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (typeof value === 'boolean') {
      // Include boolean filters only if true or if they are in alwaysInclude
      if (value || alwaysInclude.includes(key)) {
        searchParams.set(key, value.toString());
      }
    } else if (typeof value === 'number') {
      // Include numerical filters only if greater than 0
      if (value > 0) {
        searchParams.set(key, value.toString());
      }
    } else if (typeof value === 'string') {
      // Include string filters only if not empty
      if (value.trim() !== '') {
        searchParams.set(key, value);
      }
    } else if (Array.isArray(value)) {
      // Include array filters only if not empty
      if (value.length > 0) {
        searchParams.set(key, value.join(','));
      }
    } else {
      // For other types, include only if not null or undefined
      if (value !== undefined && value !== null) {
        searchParams.set(key, value.toString());
      }
    }
  });

  return searchParams;
};
