
//ViewModeContext.js
//Path:/Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/contexts/ViewModeContext.js

import React, { createContext, useContext, useState } from 'react';

const ViewModeContext = createContext();

export const ViewModeProvider = ({ children }) => {
  const [viewMode, setViewMode] = useState('both');
  const [isManuallySet, setIsManuallySet] = useState(false);

  return (
    <ViewModeContext.Provider value={{ viewMode, setViewMode, setIsManuallySet, isManuallySet }}>
      {children}
    </ViewModeContext.Provider>
  );
};

export const useViewMode = () => useContext(ViewModeContext);
